import React from "react";
import { Container } from "./styles";

export function Work() {
  return (
    <Container id="work">
      <h2>Timeline</h2>
      <ul className="timeline">
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">MEng, Computer Science</span>
              <span className="time-wrapper">
                <span className="time">2023 - present</span>
              </span>
            </div>
            <div className="desc">
              <strong>University of Cincinnati</strong>
              <p>
                Coursework: Advanced Algorithms, Deep Learning, Intelligent Data
                Analysis, Data Security & Privacy, Software Testing & QA.
              </p>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="flag">Freelance</span>
              <span className="time-wrapper">
                <span className="time">2023 - 2023</span>
              </span>
            </div>
            <div className="desc">
              <strong>Full-Stack Web Developer</strong>
              <p>
                Developed a new product with Angular, FastAPI, Neo4j considering
                all stages of production, including development, testing and
                beta release.
              </p>
              <p></p>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">Angular Minds</span>
              <span className="time-wrapper">
                <span className="time">2021 - 2023</span>
              </span>
            </div>
            <div className="desc">
              <strong>Software Engineer</strong>
              <p>
                Lead the development of responsive and intuitive user interfaces
                using Angular, coupled with server-side REST APIs using FastAPI
                and Flask, across multiple products.
              </p><br />
              <p>
                Developed a webapp enabling seamless AI model deployment for a
                leading American multinational financial services corporation,
                automating the process, and streamlined approval chain.
              </p><br />

              <p>
                Drove a transformative upgrade of a student information system
                for a renowned American Business School, transitioning from
                Angular 2 to then latest Angular 14 version, including the
                development of bespoke libraries to replace deprecated npm
                packages.
              </p>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="flag">Infosys</span>
              <span className="time-wrapper">
                <span className="time">2020 - 2021</span>
              </span>
            </div>
            <div className="desc">
              <strong>Systems Engineer</strong>
              <p>
                Successfully leveraged ReactJS to develop interactive and
                dynamic features for web applications, ensuring optimal user
                experiences for large US based FMCG organization
              </p>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">BE, Computer Engineering</span>
              <span className="time-wrapper">
                <span className="time">2016 - 2020</span>
              </span>
            </div>
            <div className="desc">
              <strong>Savitribai Phule Pune University</strong>
              <p>
                Coursework: Web Technologies, Data Structures & Algorithms,
                Software Engineering & Project Management, Data Analytics
              </p>
            </div>
          </div>
        </li>
      </ul>
    </Container>
  );
}
