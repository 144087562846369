import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/phone-icon.svg"
import { Form } from "../Form/Form";


export function Contact(){

  return(
    <Container id="contact">
      <header>
        <h2>Get in touch</h2>
        <p>I'm currently looking for Software Engineering or Machine Learning opportunities! If you know of any positions available, if you have any questions, or if you just want to say hi, please feel free to email me at, <a href="mailto:atharvapingale@gmail.com">atharvapingale@gmail.com</a> </p>
        
      </header>
    </Container>
  )
}