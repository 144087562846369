import { Container } from './styles'

import reactIcon from '../../assets/react-icon.svg'

export function Footer() {
  return (
    <Container className="footer">
      
      <div>
        <div className='p'>
          Made with <img src={reactIcon} alt="React" />
        </div>
        <br/>
      <div className='small'>This website uses Google Analytics, Review <a href='https://policies.google.com/technologies/partner-sites'>Google's Privacy & Terms</a></div>
      </div>

    </Container>
  )
}
