import { Container } from "./styles";
import { BrowserRouter as Router } from "react-router-dom";
import { NavHashLink, HashLink } from "react-router-hash-link";
import { useState } from "react";
import APlight from "../../assets/AP-light.svg";
import APdark from "../../assets/AP-dark.svg";

// import CV from '../../assets/CV_AtharvaPingale_2022.pdf'
export function Header() {
  const [isActive, setActive] = useState(false);
  const [isLight, setLight] = useState(false);

  function toggleTheme() {
    let html = document.getElementsByTagName("html")[0];
    html.classList.toggle("light");
    setLight((curr) => !curr);
  }

  function closeMenu() {
    setActive(false);
  }

  return (
    <Container className="header-fixed">
      <Router>
        <HashLink smooth to="#home" className="logo">
          {isLight ? (
            <img src={APlight} alt="home" height={40} width={40} />
          ) : (
            <img src={APdark} alt="home" height={40} width={40} />
          )}
        </HashLink>

        

        <nav className={isActive ? "active" : ""}>
          <button
            className="dark-mode-toggle"
            aria-hidden="true"
            onClick={toggleTheme}
          >
            <svg
              className={`dark-mode-toggle__icon " + ${
                isLight ? "dark-mode-toggle__icon--moon" : ""
              }`}
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <defs>
                <mask id="mask">
                  <rect x="0" y="0" width="100%" height="100%" fill="white" />
                  <circle
                    className="dark-mode-toggle__cut-out"
                    r="6"
                    cx="24"
                    cy="10"
                    fill="black"
                  />
                </mask>
              </defs>
              <circle
                className="dark-mode-toggle__center-circle"
                r="6"
                cx="12"
                cy="12"
                fill="currentColor"
                mask="url(#mask)"
              />
              <g
                className="dark-mode-toggle__rays"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              >
                <line x1="12" x2="12" y1="3" y2="1" />
                <line x1="21" x2="23" y1="12" y2="12" />
                <line x1="12" x2="12" y1="21" y2="23" />
                <line x1="1" x2="3" y1="12" y2="12" />
              </g>
              <g
                className="dark-mode-toggle__rays"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                transform="rotate(45 12 12)"
              >
                <line x1="12" x2="12" y1="3" y2="1" />
                <line x1="21" x2="23" y1="12" y2="12" />
                <line x1="12" x2="12" y1="21" y2="23" />
                <line x1="1" x2="3" y1="12" y2="12" />
              </g>
            </svg>
          </button>
          <NavHashLink smooth to="#home" onClick={closeMenu}>
            Home
          </NavHashLink>
          <NavHashLink smooth to="#about" onClick={closeMenu}>
            About me
          </NavHashLink>
          <NavHashLink smooth to="#work" onClick={closeMenu}>
            Timeline
          </NavHashLink>
          <NavHashLink smooth to="#contact" onClick={closeMenu}>
            Contact
          </NavHashLink>
          {/* <a href={CV} download className="button">
            CV
          </a> */}
        </nav>

        <div
          aria-expanded={isActive ? "true" : "false"}
          aria-haspopup="true"
          aria-label={isActive ? "Fechar menu" : "Abrir menu"}
          className={isActive ? "menu active" : "menu"}
          onClick={() => {
            setActive((curr) => !curr);
          }}
        ></div>
      </Router>
    </Container>
  );
}
