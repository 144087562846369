import { Container } from "./styles";
import externalLink from "../../assets/external-link.svg"
import ScrollAnimation from "react-animate-on-scroll";


export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">

      <ScrollAnimation animateIn="zoomIn">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#A6D1E6 " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://devpost.com/software/paisa" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Paisa - Streamlining Expenses, Simplifying Splitting.</h3>
              <p>
              Paisa is an OCR-powered receipt buddy for seamless expense splitting.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Flask</li>
                <li>Google Gemini</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      
      <ScrollAnimation animateIn="zoomIn">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#A6D1E6 " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://atharvapingale.github.io/tensorflow-vue/" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Object Recognition using Tensorflow</h3>
              <p>
                Client side object recognition using tensorflow.js library.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Vue</li>
                <li>Tensorflow.js</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

      <ScrollAnimation animateIn="zoomIn">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#A6D1E6 " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://play.google.com/store/apps/details?id=com.atharva.usetime" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>TimeR - Android Screen Time Tracker</h3>
              <p>
              TimeR stays on top in a floating window so that you can always be aware of your phone usage. You also get alerts in fixed time intervals.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Android</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="zoomIn">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#A6D1E6 " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              {/* <div className="project-links">
                <a href="https://play.google.com/store/apps/details?id=com.atharva.usetime" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div> */}
            </header>
            <div className="body">
              <h3>Decentralized Messenger</h3>
              <p>
              A secure chat app using a peer-to-peer (p2p) messaging protocol.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React Native</li>
                <li>Web3</li>
                <li>Ethereum</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="zoomIn">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#A6D1E6 " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/AtharvaPingale/Pick-n-Place" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Pick and Place Robot</h3>
              <p>
              Designed a versatile vehicle controlled via an Android smartphone, complete with obstacle-avoidance capabilities, revolutionizing automated movement in constrained spaces.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Arduino</li>
                <li>Embedded Systems</li>
                <li>Flutter</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="zoomIn">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#A6D1E6 " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://atharvapingale.github.io/covid-stats/" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Covid Stats</h3>
              <p>
              A dashboard to view country-wise covid statistics in a graphical form
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Chart.js</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="zoomIn">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#A6D1E6 " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/AtharvaPingale/AttendanceManagement" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Attendance Management</h3>
              <p>
              Developed an Android app for maintaining over 200 students' attendance.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Android</li>
                <li>Java</li>
                <li>Firebase</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

      </div>
    </Container>
  );
}