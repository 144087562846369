import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { Main } from "./components/Main/Main";
import { GlobalStyle } from "./styles/global";
import "react-toastify/dist/ReactToastify.css";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore/lite";
import { initializeApp } from "firebase/app";
import { useEffect } from "react";

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyCu5rUFj-FV3EBE36UuGJ39BjoSboSXq6o",
    authDomain: "portfolio-65ff5.firebaseapp.com",
    projectId: "portfolio-65ff5",
    storageBucket: "portfolio-65ff5.appspot.com",
    messagingSenderId: "349221209697",
    appId: "1:349221209697:web:3a0ba5d6ff173f3a798c20",
    measurementId: "G-MEMDD9SL97",
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const trackUser = async ( ref:string ) => {
    await addDoc(collection(db, ref), { time: new Date().toString(), });
    // await addDoc(collection(db, new Date().toLocaleDateString('en-IN', { timeZone: 'America/New_York' })), { ref: new Date().toString(), });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref') ||  'missing';
    trackUser(ref);
  }, []);
  
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
    </>
  );
}

export default App;
