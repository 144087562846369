import styled from "styled-components";


export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 10rem;
  
  background-color: (--black);
  
  backdrop-filter: blur(6px);

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;

  nav{
    display: flex;
    align-items: center;
    gap: 1.8rem;
    a{
      color: #FFFF;
      padding: 0.6rem;
      font-family: 'Red Hat Display', sans-serif;
      font-weight: 500;
      text-transform: uppercase;
      transition: filter 0.25s;

      &.button{
        padding: 0.6rem 5rem;
      }

      &:hover{
        filter: brightness(0.6);
      }
    }

  }

  .menu-container{
    cursor: pointer;
    padding: 0.6rem 0;
  }

  .menu{
    width: 2rem;
    height: 0.2rem;
    background: #FFFF;
    position: relative;
    cursor: pointer;
    display: none;

    &:before{
      bottom: 0.5rem;
    }
    &:after{
      top: 0.5rem;
    }


    &.active:before{
      bottom: 0;
      transform: rotate(45deg);
    }

    &.active:after{
      top: 0;
      transform: rotate(135deg);
    }

    &.active{
      background-color: rgba(0, 0, 0, 0);
    }

  }

  .menu:before, .menu:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 0.2rem;
    background: #FFFF;
    cursor: pointer;
    transition: .6s;
  }




  @media (max-width: 960px){
    padding: 1.8rem 3rem;

    .menu{
      display: block;
    }

    nav {
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: var(--blue);
      top: 0;
      left: 0;
      transition: opacity 0.25s;
      background-color: var(--green);

      a.button{
        background-color: var(--pink);
      }

      &.active{
        opacity: 1;
        background-color: var(--blue);
        visibility: visible;
      }
    }
  }

  .dark-mode-toggle {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  
  .dark-mode-toggle__icon {
    width: 30px;
    height: auto;
    color: #F2C94C;
    transition: color .2s linear;
    display:flex;
  }
  
  .dark-mode-toggle__icon:hover {
    color: #F6C11F;
  }
  
  .dark-mode-toggle__icon--moon {
    color: gray;
    opacity: .8;
    transition: opacity .2s linear, color .2s linear;
  }
  
  .dark-mode-toggle__icon--moon:hover {
    color: gray;
    opacity: 1;
  }
  
  .dark-mode-toggle__rays {
    transition: opacity .2s linear .2s;
  }
  
  .dark-mode-toggle__icon--moon .dark-mode-toggle__rays {
    transition: opacity .2s linear;
    opacity: 0;
  }
  
  .dark-mode-toggle__cut-out {
    transition: transform .5s cubic-bezier(0.54,-0.42, 0.29, 1.3);
  }
  
  .dark-mode-toggle__icon--moon .dark-mode-toggle__cut-out {
    transform: translateX(-30%);
    transition: transform .5s cubic-bezier(0.21, 0.17, 0.43, 1.43);
  }
  
  .my-menu-items{
    display:flex;
    gap:4rem;
  }
  
`